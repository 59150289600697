import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { PropTypes } from "prop-types";
import {
  ChantierService,
  StatutChantierService,
  ProduitInterneService,
  PersonnelService,
} from "_services/";
import { Helmet } from "react-helmet";

import { App } from "App";

class BaseChantier extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base affaire</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Chantier"
          service={ChantierService.getAllViaPost}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Chantier", "Extraction") == "RW"
          }
          serviceExtract={ChantierService.Extract}
          module="Chantier"
          orderByDefault="reference"
          orderDefault="DESC"
          localStorageKeyColumns="BaseChantierColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Statut",
              accessor: "statut",

              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "multipleSelectSearch",
              defaultValue: [
                {
                  id: 3,
                  designation: "En cours",
                },
              ],
              service: StatutChantierService.getAll,
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },

            {
              Header: "N° Affaire",
              accessor: "affaire",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              search: "affaire",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/affaires/"
                    id={row.cell.value?.id}
                    value={row.cell.value?.reference}
                  />
                );
              },
            },
            {
              Header: "Produit interne",
              accessor: "affaire.produitInterne",
              search: "produitInterne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Année",
              accessor: "anneeChantier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Semaine",
              accessor: "semaineChantier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Techniciens",
              accessor: "techniciens",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              defaultValue: null,

              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: PersonnelService.getAll,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Jours/Nuits prev",
              accessor: "nbJoursNuitsPrev",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "JoursNuitsPrev",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Jours/Nuits Réels",
              accessor: "nbJoursNuitsReels",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "JoursNuitsReel",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Sondages prev",
              accessor: "nbSondagesPrev",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              search: "SondagesPrev",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Sondages réels",
              accessor: "nbSondagesReels",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "SondagesReel",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseChantier.propTypes = {
  history: PropTypes.object,
};

export { BaseChantier };
