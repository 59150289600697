import React from "react";
import {
  IndeterminateCheckbox,
  ReactTableServerSide,
  SubTableContacts,
} from "_components";

import { RedirectTo, getIconClient, makeEmailClickable } from "_utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import {
  ClientService,
  EtatClientService,
  RegionService,
  PaysService,
  TypeClientService,
} from "_services";
import { PropTypes } from "prop-types";
import { CellWithRedirection } from "_components/ReactTable/ReactTableServerSide";

import { customFuseSearch } from "_components/Input/InputTypes/SelectSearchFunctions";

import { Helmet } from "react-helmet";

import { App } from "App";

class BaseClient extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      clientIdsFromGeomarket: props.location.state?.clientIdsFromGeomarket,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <Helmet>
          <title>Base client</title>
        </Helmet>
        <ReactTableServerSide
          title={
            this.state.clientIdsFromGeomarket
              ? "Base Client (clients provenants de Géomarket)"
              : "Base Client"
          }
          service={(params) =>
            ClientService.getAllViaPost({
              ...params,
              dataIdsToLoad: this.state.clientIdsFromGeomarket,
            })
          }
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Client", "Extraction") == "RW" ||
            App.RightsGuard.current?.hasRight("Client", "Extraction Limitee") ==
              "RW"
          }
          isLimitedExtraction={
            App.RightsGuard.current?.hasRight("Client", "Extraction Limitee") ==
            "RW"
          }
          serviceExtract={(params) =>
            ClientService.ExtractViaPost({
              ...params,
              dataIdsToLoad: this.state.clientIdsFromGeomarket,
            })
          }
          module="Client"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/clients/" + "add");
          }}
          orderByDefault="nom_Client"
          localStorageKeyColumns="baseClientColumns"
          objectFieldToExport={{
            etat: "qualification",
            paysLivraisonPrincipale: "adresse_Siege_Social.pays.nom_Fr",
            paysFacturation: "adresse_Facturation.pays.nom_Fr",
            regionLivraisonPrincipale: "adresse_Siege_Social.region.nom_Fr",
          }}
          canSelectedDataBeSendToOtherPages={true}
          subTableComponent={SubTableContacts}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "",
              id: "listeContacts",
              accessor: "listeContacts",
              minWidth: "25px",
              Cell: ({ row }) => (
                <span
                  {...row.getToggleRowExpandedProps()}
                  tabIndex="0"
                  className="listeContacts"
                >
                  {row.isExpanded ? (
                    <FontAwesomeIcon
                      icon={faSortDown}
                      size="lg"
                      className="mx-auto d-block"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSortUp}
                      size="lg"
                      className="mx-auto d-block rotateSortUp"
                    />
                  )}
                </span>
              ),
            },
            {
              Header: "Actif",
              accessor: "actif",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: {
                id: 1,
                display: "Oui",
                value: true,
              },
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non{" "}
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "Qualification",
              accessor: "etat",
              minWidth: "200px",
              hideable: true,
              sortable: true,
              exportable: true,
              type: "multipleSelectSearch",
              defaultValue: null,
              service: EtatClientService.getAll,
              optionFieldToDisplay: "qualification",
              valueFieldToDisplay: "qualification",
              optionFieldToReturn: "id",
              Cell: (row) => {
                return (
                  <div className="d-flex justify-content-start align-items-center">
                    {getIconClient(row.cell.value.qualification, "small")}{" "}
                    {row.cell.value.qualification}
                  </div>
                );
              },
            },
            {
              Header: "Nom",
              accessor: "nom_Client",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/clients/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Compte Tiers",
              accessor: "compte_Tier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "text",
            },
            {
              Header: "Type",
              id: "type",
              accessor: "type_Client",
              hideable: true,
              sortable: true,
              minWidth: "200px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "type",
              valueFieldToDisplay: "type",
              optionFieldToReturn: "id",
              service: TypeClientService.getAll,
              Cell: (row) => {
                return (
                  <span>{row.cell.value ? row.cell.value.type : null}</span>
                );
              },
            },
            {
              Header: "Catégorie",
              id: "categorie",
              accessor: "type_Client",
              search: "categorie",
              hideable: true,
              sortable: true,
              minWidth: "200px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "type",
              valueFieldToDisplay: "type",
              optionFieldToReturn: "id",
              service: TypeClientService.getAllCategoryFromTypeClient,
              Cell: (row) => {
                return row.cell.value && row.cell.value.categorie ? (
                  <span>{row.cell.value.categorie.type}</span>
                ) : null;
              },
            },
            {
              Header: "Litige",
              accessor: "litige",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "120px",
              type: "selectSearch",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              optionFieldToReturn: "value",
              defaultValue: null,
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return (
                    <div>
                      Oui{" "}
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                } else {
                  return <div>Non</div>;
                }
              },
            },

            {
              Header: "Email",
              accessor: "adresse_Siege_Social.email",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              search: "email",
              type: "email",
              Cell: (row) => {
                return <span>{makeEmailClickable(row.cell.value)}</span>;
              },
            },
            {
              Header: "Téléphone",
              accessor: "adresse_Siege_Social.telephone",
              search: "tel_Liv",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "text",
            },
            {
              Header: "Ville",
              accessor: "adresse_Siege_Social.ville",
              search: "ville_Liv",
              hideable: true,
              sortable: true,

              exportable: true,
              minWidth: "170px",
              type: "text",
            },
            {
              Header: "Code postal",
              accessor: "adresse_Siege_Social.cp",
              search: "cp_Liv",
              minWidth: "130px",
              hideable: true,
              sortable: true,

              exportable: true,
              type: "text",
            },
            {
              Header: "Région",
              accessor: "adresse_Siege_Social.region",
              search: "region",
              minWidth: "170px",
              hideable: true,
              sortable: true,

              exportable: true,
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "nouvelle_Region",
              optionFieldToReturn: "nouvelle_Region",
              valueFieldToDisplay: "nouvelle_Region",
              service: RegionService.getAllNouvelleRegions,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.nouvelle_Region}</span>
                ) : null;
              },
            },
            {
              Header: "Pays",
              accessor: "adresse_Siege_Social.pays",
              search: "pays_Liv",
              hideable: true,
              sortable: true,

              exportable: true,
              minWidth: "170px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "nom_Fr",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "nom_Fr",
              customFilter: (options) =>
                customFuseSearch(options, {
                  threshold: 0.4,
                  ignoreLocation: true,
                }),
              service: PaysService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.nom_Fr}</span>
                ) : null;
              },
            },
            {
              Header: "Précision GPS",
              accessor: "adresse_Siege_Social.precision",
              search: "precision",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              defaultValue: null,
              options: [
                {
                  id: 1,
                  display: "Précise",
                  value: 1,
                },
                {
                  id: 2,
                  display: "Imprécise",
                  value: 2,
                },
                {
                  id: 3,
                  display: "Inexistante",
                  value: 3,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == 1) {
                  return <div>Précise</div>;
                } else if (row.cell.value == 2) {
                  return <div>Imprécise</div>;
                } else {
                  return <div>Inexistante</div>;
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseClient.propTypes = {
  history: PropTypes.object,
};

export { BaseClient };
