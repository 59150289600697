import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import Autocomplete from "@mui/material/Autocomplete";
import { isEqual } from "lodash";
import { getNestedProperty } from "_utils";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import "./MultipleSelectSearch.css";

class MultipleSelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options ?? [],
      value: this.props.value ? this.props.value : [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.initValue = this.initValue.bind(this);
    this.getSelectedOptions = this.getSelectedOptions.bind(this);
    this.getOptionFieldToDisplay = this.getOptionFieldToDisplay.bind(this);
    this.getValueFieldToDisplay = this.getValueFieldToDisplay.bind(this);
  }

  componentDidMount() {
    if (this.props.service) {
      Promise.resolve(this.props.service()).then((res) => {
        this.setState(
          { options: res.data.datas ?? res.data, loading: false },
          () => {
            this.initValue();
          }
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value && Array.isArray(this.props.value))
      this.initValue();
  }

  getOptionFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.optionFieldToDisplay && option) {
      if (Array.isArray(this.props.optionFieldToDisplay)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.optionFieldToDisplay
          .map((key) => getNestedProperty(option, key))
          .join(" | ");
        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.optionFieldToDisplay]?.toString();
      }
    }
    // Si on utilise un type primitif
    return option ? option : "";
  }

  getValueFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.valueFieldToDisplay && option) {
      if (Array.isArray(this.props.valueFieldToDisplay)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.valueFieldToDisplay
          .map((key) => getNestedProperty(option, key))
          .join(" | ");

        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.valueFieldToDisplay]?.toString();
      }
    }

    // Si on utilise un type primitif
    return option ? option.designation : "";
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  initValue() {
    const selectedOptions = this.getSelectedOptions();
    this.setState({ value: selectedOptions });
  }

  getSelectedOptions() {
    const value = this.props.value ? this.props.value : [];

    const selectedOptions = this.state.options?.filter((option) =>
      value.find((value) => value.id === option.id)
    );

    return selectedOptions;
  }

  handleChange(value) {
    this.setState({ value: value }, () => {
      if (this.props.optionFieldToReturn) {
        this.props.handleChange(
          this.props.accessor,
          this.state.value.map((x) => x[this.props.optionFieldToReturn])
        );
      } else {
        this.props.handleChange(this.props.accessor, this.state.value);
      }
    });
  }

  isChecked(option) {
    this.state.value.forEach((value) => {
      if (option.id === value.id) {
        return true;
      }
    });
    return false;
  }

  render() {
    return (
      <div>
        {this.props.label ? (
          <div className="text-uppercase text-muted">{this.props.label}</div>
        ) : (
          ""
        )}
        <div className="input-group has-validation">
          <Autocomplete
            multiple
            className="form-select multipleSelect"
            value={this.state.value}
            options={this.state.options}
            onChange={(__event, value) => this.handleChange(value)}
            required={this.props.required}
            getOptionLabel={this.getOptionFieldToDisplay}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  type="text"
                  {...params.inputProps}
                  className="input-multipleSelectSearch"
                  placeholder={this.state.value
                    .map((item) => this.getValueFieldToDisplay(item))
                    .join(", ")}
                />
              </div>
            )}
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {this.getOptionFieldToDisplay(option)}
              </li>
            )}
            style={{
              padding: "0px",
              height: "38px",
            }}
            disableCloseOnSelect
            openOnFocus
            isOptionEqualToValue={(option, value) => option.id == value.id}
          />
          <ButtonIcon
            id={"inputGroup" + this.props.accessor}
            smallText=""
            icon={faTimes}
            iconSize="sm"
            onClick={() => {
              this.setState({ value: [] });
              this.props.handleChange(this.props.accessor, null);
            }}
            className="btn btn-danger"
            tabIndex="-1"
          />
          <div
            id={"validation" + this.props.accessor}
            className="invalid-feedback"
          >
            {this.props.invalidText}
          </div>
        </div>
      </div>
    );
  }
}

MultipleSelectSearch.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  accessor: PropTypes.any,
  handleChange: PropTypes.any,
  service: PropTypes.any,
  optionFieldToDisplay: PropTypes.any,
  valueFieldToDisplay: PropTypes.any,
  optionFieldToReturn: PropTypes.string,
  required: PropTypes.bool,
  invalidText: PropTypes.string,
};

export { MultipleSelectSearch };
