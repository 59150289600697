import React from "react";

import { ButtonIcon, Box } from "_components";
import { Input } from "_components/Input";

import { PropTypes } from "prop-types";
import { faCheck, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";

//import { isEqual } from "lodash";

// API Geocoding Nominatim : adresse vers Lat/lon
import { NominatimService } from "_services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { MapContainer, TileLayer, Marker, useMapEvent } from "react-leaflet";

import { App } from "App";

// Colored Markers for Leaflet
import {
  // blueIcon,
  // goldIcon,
  redIcon,
  greenIcon,
  // orangeIcon,
  yellowIcon,
  // violetIcon,
  // greyIcon,
  // blackIcon,
} from "_components/Leaflet/Markers";

class BoxMapLeaflet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveState: {},
      edition: false,
      loading: true,

      zoom: 5,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getCoordinatesFromAPI = this.getCoordinatesFromAPI.bind(this);
    this.loadPosition = this.loadPosition.bind(this);

    this.getColoredMarker = this.getColoredMarker.bind(this);
    this.updateMarker = this.updateMarker.bind(this);

    this.handleChangeInput = this.handleChangeInput.bind(this);

    this.handleClickOnMap = this.handleClickOnMap.bind(this);

    this.mapConsumer = this.mapConsumer.bind(this);
    this.MapClickComponent = this.MapClickComponent.bind(this);
  }

  componentDidMount() {
    if (this.state.loading) {
      this.loadPosition();
    }
  }

  componentDidUpdate() {
    if (this.state.loading) {
      this.loadPosition();
    }
  }

  handleClickEdition() {
    this.setState({
      saveState: {
        precision: this.props.precision,
        latitude: this.props.latitude,
        longitude: this.props.longitude,
      },
      edition: true,
    });
    this.props.handleEditing(true);
  }

  handleUndo() {
    this.props.handleChange(
      this.state.saveState.latitude,
      this.state.saveState.longitude,
      this.state.saveState.precision
    );

    this.setState({
      edition: false,
      loading: true,
      zoom: this.state.saveState.precision == 1 ? 8 : 5,
    });
    this.props.handleEditing(false);
  }

  handleValidate() {
    this.setState({
      edition: false,
    });
    this.props.handleEditing(false);
    this.props.handleUpdate();
  }

  handleChange(latitude, longitude, precision = 1) {
    console.log("change");
    this.props.handleChange(latitude, longitude, precision);
  }

  loadPosition() {
    if (this.props.precision == 3) {
      // Précision incertaine, on essaye de déterminer la position via une API
      this.getCoordinatesFromAPI();
    } else {
      // Précision suffisante, on utilise les coordonnées fournies.
      this.setState({
        loading: false,
        zoom: 8,
      });
    }
  }

  getCoordinatesFromAPI() {
    NominatimService.getDataFromAddress(
      this.props.voie,
      this.props.ville,
      this.props.codePostal,
      this.props.pays
    )
      .then((resByAddress) => {
        if (!resByAddress.data.length) {
          NominatimService.getDataFromQuery(
            this.props.voie +
              " " +
              this.props.ville +
              " " +
              this.props.codePostal +
              " " +
              this.props.pays
          )
            .then((resByQuery) => {
              if (resByQuery.data.length) {
                this.props.handleChange(
                  resByQuery.data[0].lat,
                  resByQuery.data[0].lon,
                  2
                );
                this.setState({
                  loading: false,
                });
              } else {
                this.setState({
                  loading: false,
                });
              }
            })
            .catch(() =>
              setTimeout(
                () =>
                  App.Toaster.current?.createToast({
                    body: "L'appel à openstreetmap a échouée : la carte ne s'affichera pas.",
                    header: "Echec",
                    type: "failure",
                  }),
                3000
              )
            );
        } else {
          this.props.handleChange(
            resByAddress.data[0].lat,
            resByAddress.data[0].lon,
            2
          );
          this.setState({
            loading: false,
          });
        }
      })
      .catch(() =>
        setTimeout(
          () =>
            App.Toaster.current?.createToast({
              body: "L'appel à openstreetmap a échouée : la carte ne s'affichera pas.",
              header: "Echec",
              type: "failure",
            }),
          3000
        )
      );
  }

  getColoredMarker() {
    if (this.props.precision == 1) {
      return greenIcon;
    } else if (this.props.precision == 2) {
      return yellowIcon;
    } else {
      return redIcon;
    }
  }

  updateMarker(event) {
    const latLng = event.target.getLatLng(); //get marker LatLng
    this.handleChange(latLng.lat, latLng.lng);
  }

  handleClickOnMap(event) {
    if (
      this.state.edition &&
      this.props.latitude === 0 &&
      this.props.longitude === 0
    ) {
      this.setState({ zoom: event.target._zoom });
      this.handleChange(event.latlng.lat, event.latlng.lng);
      this.forceUpdate();
    }
  }

  handleChangeInput(accessor, value) {
    if (accessor === "latitude") {
      this.handleChange(value, this.props.longitude);
    } else if (accessor === "longitude") {
      this.handleChange(this.props.latitude, value);
    }
  }

  mapConsumer(map) {
    if (!this.state.edition) {
      map.setView(
        this.props.latitude === 0 && this.props.longitude === 0
          ? [46, 3] // Centré sur le milieu de la France
          : [this.props.latitude, this.props.longitude],
        this.state.zoom
      );
    }
    if (this.state.edition) {
      map.on("click", this.handleClickOnMap);
    }
    return null;
  }

  MapClickComponent() {
    useMapEvent("click", this.handleClickOnMap);
    return null;
  }

  render() {
    return (
      <Box
        header={
          <>
            <div className="col-3 py-2">Carte</div>
            <div className="col-3">
              {this.state.edition ? (
                <Input
                  accessor="latitude"
                  value={this.props.latitude}
                  ignoredValues={[]}
                  type="decimal"
                  placeholder=""
                  numberOfDecimals={14}
                  handleBlur={this.handleChangeInput}
                  showValidator={false}
                  showClearButton={false}
                  disabled={!this.state.edition}
                />
              ) : null}
            </div>
            <div className="col-3">
              {this.state.edition ? (
                <Input
                  accessor="longitude"
                  value={this.props.longitude}
                  ignoredValues={[]}
                  type="decimal"
                  placeholder=""
                  numberOfDecimals={14}
                  handleBlur={this.handleChangeInput}
                  showValidator={false}
                  showClearButton={false}
                  disabled={!this.state.edition}
                />
              ) : null}
            </div>
            {!this.props.NoEdition ? (
              <div className="col-3 text-end align-self-center">
                <div role="group" className="me-1">
                  <ButtonIcon
                    icon={faCheck}
                    className="btn btn-success btn-sm text-light ms-1"
                    onClick={this.handleValidate}
                    style={
                      !this.state.edition
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                    tooltip="Valider les modifications"
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faTimes}
                    className="btn btn-danger btn-sm text-light ms-1"
                    onClick={this.handleUndo}
                    style={
                      !this.state.edition
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                    tooltip="Annuler toutes modifications"
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faPen}
                    className="btn btn-secondary btn-sm text-light ms-1"
                    onClick={this.handleClickEdition}
                    style={
                      this.state.edition || this.props.editing
                        ? { display: "none" }
                        : null
                    }
                    tooltip="Editer"
                  ></ButtonIcon>
                </div>
              </div>
            ) : null}
          </>
        }
        body={
          !this.state.loading ? (
            <MapContainer
              style={{ height: "400px", borderRadius: "0 0 15px 15px" }}
              center={
                this.props.latitude === 0 && this.props.longitude === 0
                  ? [46, 3] // Centré sur le milieu de la France
                  : [this.props.latitude, this.props.longitude]
              }
              zoom={this.state.zoom}
            >
              <this.MapClickComponent />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                noWrap={true}
              />
              {this.props.latitude !== 0 && this.props.longitude !== 0 ? (
                <Marker
                  position={[this.props.latitude, this.props.longitude]}
                  draggable={this.state.edition}
                  icon={this.getColoredMarker()}
                  eventHandlers={{
                    dragend: (e) => {
                      console.log(e);
                      this.updateMarker(e);
                    },
                  }}
                ></Marker>
              ) : null}
            </MapContainer>
          ) : (
            <div
              style={{
                padding: "20px",
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
            </div>
          )
        }
        noPaddingBottom
      />
    );
  }
}

BoxMapLeaflet.propTypes = {
  editing: PropTypes.bool,
  handleEditing: PropTypes.func,
  handleChange: PropTypes.func,
  handleUpdate: PropTypes.func,

  voie: PropTypes.string,
  ville: PropTypes.string,
  codePostal: PropTypes.string,
  pays: PropTypes.string,
  precision: PropTypes.number,
  latitude: PropTypes.any,
  longitude: PropTypes.any,
};

BoxMapLeaflet.defaultProps = {
  precision: 3,
};

export { BoxMapLeaflet };
