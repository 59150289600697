import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Box, ButtonIcon, DataFieldColumn } from "_components";
import moment from "moment";

import React from "react";

const getColorByNow = (year, week) => {
  let now = moment();
  let start = moment(new Date(year, 1, 1));
  let color = "";
  if (now.year() > start.year()) {
    // color 1
    color = "red";
  } else if (now.year() == start.year()) {
    if (now.isoWeek() == week) {
      //color 2
      color = "green";
    } else if (now.isoWeek() < week) {
      // color 1
      color = "red";
    } else {
      // color 3
      color = "yellow";
    }
  } else {
    // color 3
    color = "yellow";
  }
  return color;
};

const BlocChantierSynthese = (props) => {
  let chantier = props.chantier;

  let boxHeader = (
    <>
      <div className="col-6 align-self-center py-2">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              backgroundColor: getColorByNow(
                chantier.anneeChantier,
                chantier.semaineChantier
              ),
              marginRight: "5px",
            }}
          />
          Chantier
        </div>
      </div>
      <div className="col-6 text-end py-2">
        <ButtonIcon
          icon={faExternalLinkAlt}
          className="btn btn-secondary text-light ms-1"
          style={{ width: "42px" }}
          onClick={() => {
            props.history.push("/chantiers/" + props.chantier.id);
          }}
        />
      </div>
    </>
  );

  let box = (
    <Box
      header={boxHeader}
      body={
        <div className="row">
          <div className="col">
            <div>
              <DataFieldColumn
                label="Année"
                type="text"
                value={chantier.anneeChantier}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Semaine"
                type="text"
                value={chantier.semaineChantier}
              />
            </div>

            <div>
              <DataFieldColumn
                label="PP"
                type="text"
                value={chantier.planPrevention}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="HeureRDV"
                type="text"
                value={chantier.heureRDV}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Contact"
                type="text"
                value={chantier.contact}
                functionAppliedToValue={(value) => {
                  return value ? (
                    <a
                      href={"/contacts/" + value.id}
                      target="_blank"
                      data-text={value.nom_Contact + " " + value.prenom_Contact}
                      className="fixWidthHover"
                    >
                      {value.nom_Contact + " " + value.prenom_Contact}
                    </a>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Labo"
                type="text"
                value={chantier.labo}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Lieu RDV"
                type="text"
                value={chantier.lieuRDV}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="nuitprev"
                type="text"
                value={chantier.nbNuitsPrev}
              />
            </div>
            <div>
              <DataFieldColumn
                label="jourprev"
                type="text"
                value={chantier.nbJoursPrev}
              />
            </div>
            <div>
              <DataFieldColumn
                label="hotel"
                type="text"
                value={chantier.hotel}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Heure Bureau"
                type="text"
                value={chantier.heureArriveeBureau}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Sondages prev"
                type="text"
                value={chantier.nbSondagesPrev}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="techniciens"
                type="text"
                value={chantier.techniciens}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.initiales}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Vehicules"
                type="text"
                value={chantier.vehicules}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.designation}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>

            <div>
              <DataFieldColumn
                label="Prepa Endo"
                type="text"
                value={chantier.preparateurEndo}
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Endo"
                type="text"
                value={chantier.materielEndo}
                functionAppliedToValue={(value) => {
                  return value ? value.num_Serie : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Prepa Panda"
                type="text"
                value={chantier.preparateurPanda}
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Panda"
                type="text"
                value={chantier.materielPanda}
                functionAppliedToValue={(value) => {
                  return value ? value.num_Serie : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Spé"
                type="text"
                value={chantier.materielSpecifique}
              />
            </div>
          </div>
          <div className="col">
            <DataFieldColumn
              label="Commentaire"
              type="text"
              value={chantier.commentaire}
            />
          </div>
        </div>
      }
    ></Box>
  );

  return <div>{box}</div>;
};

export { BlocChantierSynthese };
