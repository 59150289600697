import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ButtonIconWithSmall, DialogForm } from "_components";
import { Input } from "_components/Input";
import {
  ChantierService,
  ContactService,
  MaterielService,
  PersonnelService,
} from "_services";
import { VehiculeService } from "_services/VehiculeService";
import React, { useState, useEffect } from "react";
import { BlocChantier } from "./BlocChantier";
import moment from "moment";

const getAllMaterielLocationProxy = (search) => {
  return MaterielService.getAllSolSolution({
    num_serie: search,
  });
};
const getArrayOfWeekByYear = (year) => {
  if (year == undefined) return [];
  let start = moment(new Date(year, 1, 1));
  let now = moment();

  let arrayWeeks = [];
  if (now.year() == start.year()) {
    for (let i = now.isoWeek() + 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  } else {
    for (let i = 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  }
  return arrayWeeks;
};

const getArrayOfYears = () => {
  let now = moment();
  let year = now.year();
  let arrayYears = [];
  for (let i = 0; i < 3; i++) {
    arrayYears.push(year + i);
  }
  return arrayYears;
};

const ChantierContainer = (props) => {
  let [chantiers, setChantiers] = useState([]);
  let [newChantier, setNewChantier] = useState({
    techniciens: [],
    vehicules: [],
  });
  let [openForm, setOpenForm] = useState(() => {});
  let [forceUpdateWeek, setForceUpdateWeek] = useState(() => null);

  const handleGetAll = () => {
    ChantierService.getByIdAffaire(props.affaire?.id).then((e) =>
      setChantiers(e.data)
    );
  };

  const updateOne = (id) => {
    ChantierService.put(chantiers.filter((e) => e.id == id)[0])
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  const handleChangeNew = (accessor, value) => {
    let newChantierTmp = { ...newChantier, [accessor]: value };

    if (accessor == "anneeChantier") {
      forceUpdateWeek();
      newChantierTmp = { ...newChantierTmp, semaineChantier: null };
    }
    setNewChantier(newChantierTmp);
  };

  const handleChangeUpdate = (accessor, value, id) => {
    let index = chantiers.findIndex((e) => e.id == id);
    let chantierToUpdate = chantiers[index];
    let chantierUpdated = { ...chantierToUpdate, [accessor]: value };
    const newArray = [...chantiers];
    newArray[index] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleChangePeriodeUpdate = (
    accessor,
    value,
    idChantier,
    idPeriode
  ) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];
    let indexPeriode = chantierToUpdate.periodeChantiers.findIndex(
      (e) => e.id == idPeriode
    );
    let periodeToUpdate = chantierToUpdate.periodeChantiers[indexPeriode];
    let periodeUpdated = { ...periodeToUpdate, [accessor]: value };

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers[indexPeriode] = periodeUpdated;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleAddPeriode = (idChantier, periode) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];

    let arrayPeriode = [...chantierToUpdate.periodeChantiers, periode];

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers = arrayPeriode;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleResetChantier = (idchantier, reset) => {
    let index = chantiers.findIndex((e) => e.id == idchantier);
    let chantierReseted = { ...reset };
    const newArray = [...chantiers];
    newArray[index] = chantierReseted;

    setChantiers(newArray);
  };

  const serviceGetContactsByClientId = () => {
    if (props.affaire.client.id) {
      return ContactService.getByIdClient(props.affaire.client.id);
    }
  };
  const setOpenDialog = (func) => {
    setOpenForm(() => func);
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  let chantierHtml = chantiers
    ?.sort((a, b) => {
      if (a.anneeChantier == b.anneeChantier) {
        return a.semaineChantier - b.semaineChantier;
      } else if (a.anneeChantier < b.anneeChantier) {
        return -1;
      } else {
        return 1;
      }
    })
    .map((e) => (
      <BlocChantier
        key={e.id}
        chantier={e}
        affaire={props.affaire}
        handleGetAll={handleGetAll}
        update={updateOne}
        handleChange={handleChangeUpdate}
        handleChangePeriode={handleChangePeriodeUpdate}
        handleAddPeriode={handleAddPeriode}
        handleResetChantier={handleResetChantier}
      ></BlocChantier>
    ));

  let dialogAddChantier = (
    <DialogForm
      tooltip="Ajouter un historique"
      classNameButton="btn btn-success"
      dialogTitle="Ajouter un historique"
      labelValidateButton="Valider"
      setOpenDialog={setOpenDialog}
      onValidate={() => {
        ChantierService.post({ ...newChantier, affaire: props.affaire }).then(
          () => {
            handleGetAll();
            setNewChantier = {};
          }
        );
      }}
      onClose={() => {}}
      op
      body={
        <div
          id="PopupCommande"
          className={"col-lg-12 row"}
          style={{ minWidth: "1000px" }}
        >
          <div className="row">
            <div className="col">
              <div>
                <Input
                  label="Année"
                  value={newChantier.anneeChantier}
                  accessor="anneeChantier"
                  handleBlur={handleChangeNew}
                  type="selectSearch"
                  options={getArrayOfYears()}
                  required
                />
              </div>
              <div>
                <Input
                  label="Semaine"
                  value={newChantier.semaineChantier}
                  accessor="semaineChantier"
                  handleBlur={handleChangeNew}
                  type="selectSearch"
                  options={getArrayOfWeekByYear(newChantier.anneeChantier)}
                  setForceUpdateFunction={(callback) =>
                    setForceUpdateWeek(() => callback)
                  }
                />
              </div>
            </div>

            <div className="col">
              <div>
                <Input
                  label="PP"
                  value={newChantier.planPrevention}
                  accessor="planPrevention"
                  handleChange={handleChangeNew}
                  type="checkbox"
                />
              </div>
              <div>
                <Input
                  label="HeureRDV"
                  value={newChantier.heureRDV}
                  accessor="heureRDV"
                  handleChange={handleChangeNew}
                  type="time"
                />
              </div>
              <div>
                <Input
                  label="Contact"
                  value={newChantier.contact}
                  accessor="contact"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={serviceGetContactsByClientId}
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                />
              </div>
              <div>
                <Input
                  label="Labo"
                  value={newChantier.labo}
                  accessor="labo"
                  handleChange={handleChangeNew}
                  type="checkbox"
                />
              </div>
              <div>
                <Input
                  label="Lieu RDV"
                  value={newChantier.lieuRDV}
                  accessor="lieuRDV"
                  handleChange={handleChangeNew}
                  type="text"
                />
              </div>
            </div>

            <div className="col">
              <div>
                <Input
                  label="Nuits prévues"
                  value={newChantier.nbNuitsPrev}
                  accessor="nbNuitsPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={2}
                />
              </div>
              <div>
                <Input
                  label="Jours prévus"
                  value={newChantier.nbJoursPrev}
                  accessor="nbJoursPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={2}
                />
              </div>
              <div>
                <Input
                  label="Hôtel"
                  value={newChantier.hotel}
                  accessor="hotel"
                  handleChange={handleChangeNew}
                  type="checkbox"
                />
              </div>
              <div>
                <Input
                  label="Heure Arrivée bureau"
                  value={newChantier.heureArriveeBureau}
                  accessor="heureArriveeBureau"
                  handleChange={handleChangeNew}
                  type="time"
                />
              </div>
              <div>
                <Input
                  label="Nombre de sondages prévus"
                  value={newChantier.nbSondagesPrev}
                  accessor="nbSondagesPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={2}
                />
              </div>
            </div>
            <div className="col">
              <Input
                label="Techniciens"
                value={newChantier.techniciens}
                accessor="techniciens"
                handleChange={handleChangeNew}
                type="multipleSelectSearch"
                service={PersonnelService.getAll}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
              />
              <Input
                label="Vehicules"
                value={newChantier.vehicules}
                accessor="vehicules"
                handleChange={handleChangeNew}
                type="multipleSelectSearch"
                service={VehiculeService.getAll}
                optionFieldToDisplay={"designation"}
                valueFieldToDisplay={"designation"}
              />
            </div>
            <div className="col">
              <div>
                <Input
                  label="Prependo"
                  value={newChantier.preparateurEndo}
                  accessor="preparateurEndo"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={PersonnelService.getAll}
                  optionFieldToDisplay={["nom", "prenom"]}
                  valueFieldToDisplay={["nom", "prenom"]}
                />
              </div>
              <div>
                <Input
                  label="Materiel Endo"
                  value={newChantier.materielEndo}
                  accessor="materielEndo"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={getAllMaterielLocationProxy}
                  optionFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                  valueFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                />
              </div>
              <div>
                <Input
                  label="PrepPanda"
                  value={newChantier.preparateurPanda}
                  accessor="preparateurPanda"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={PersonnelService.getAll}
                  optionFieldToDisplay={["nom", "prenom"]}
                  valueFieldToDisplay={["nom", "prenom"]}
                />
              </div>
              <div>
                <Input
                  label="Materiel Panda"
                  value={newChantier.materielPanda}
                  accessor="materielPanda"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={getAllMaterielLocationProxy}
                  optionFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                  valueFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                />
              </div>
              <div>
                <Input
                  label="Materiel Spécifique"
                  value={newChantier.materielSpecifique}
                  accessor="materielSpecifique"
                  handleChange={handleChangeNew}
                  type="text"
                  optionFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                  valueFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                />
              </div>
            </div>
            <div className="col">
              <Input
                label="Commentaire"
                value={newChantier.commentaire}
                accessor="commentaire"
                handleChange={handleChangeNew}
                type="textArea"
                showClearButton={false}
              />
            </div>
          </div>
        </div>
      }
    />
  );
  return (
    <>
      {dialogAddChantier}
      {chantierHtml}

      <div
        className={"scrollPetitEcran row tableauBodyInformations bg-white "}
        style={{
          flexGrow: 1,
          border: "1px solid black",
          placeContent: "flex-start",
          borderRadius: "15px",
          textAlign: "center",
          padding: "10px",
        }}
      >
        <ButtonIconWithSmall
          icon={faPlus}
          className="btn btn-success text-light ms-1"
          onClick={() => openForm()}
        />
      </div>
    </>
  );
};

export { ChantierContainer };
