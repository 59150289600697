/* eslint-disable no-undef */
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import { msalInstance } from "index";
import {
  msalConfig,
  protectedResources,
  graphConfig,
  loginRequest,
} from "./authConfig";

export function getToken() {
  let accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    return new Promise((resolve) => {
      msalInstance
        .acquireTokenSilent({
          ...msalConfig,
          account: accounts[0],
          scopes: protectedResources.apiBamboo.scopes,
        })
        .then((response) => {
          resolve(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            msalInstance
              .acquireTokenRedirect({
                ...msalConfig,
                account: accounts[0],
                scopes: protectedResources.apiBamboo.scopes,
              })
              .then((response) => {
                resolve(response.accessToken);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
    });
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getToken();
    if (
      accessToken != null &&
      !config.url.includes("nominatim.openstreetmap.org")
    ) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    if (config.headers == null || config.headers == undefined) {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function getTokenForGraph() {
  let accounts = msalInstance.getAllAccounts();
  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .then((response) => response.accessToken);
}

export async function getProfilePicture(oid) {
  const accessToken = await getTokenForGraph();
  if (accessToken != null) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-type", "blob");
    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(graphConfig.graphMeEndpoint + `${oid}/photo/$value`, options)
      .then((response) => {
        if (!response.ok)
          throw new Error("Not 2xx response", { cause: response });
        return response.blob();
      })
      .then((e) => URL.createObjectURL(e));
  }
}

export default axiosInstance;
