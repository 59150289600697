import { ChantierService } from "_services";
import React, { useState, useEffect } from "react";
import { BlocChantierSynthese } from "./BlocChantierSynthese";

const ChantierContainer = (props) => {
  let [chantiers, setChantiers] = useState([]);

  const handleGetAll = () => {
    ChantierService.getMesChantiers().then((e) => setChantiers(e.data));
  };

  const updateOne = (id) => {
    ChantierService.put(chantiers.filter((e) => e.id == id)[0])
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  const handleChangeUpdate = (accessor, value, id) => {
    let index = chantiers.findIndex((e) => e.id == id);
    let chantierToUpdate = chantiers[index];
    let chantierUpdated = { ...chantierToUpdate, [accessor]: value };
    const newArray = [...chantiers];
    newArray[index] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleChangePeriodeUpdate = (
    accessor,
    value,
    idChantier,
    idPeriode
  ) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];
    let indexPeriode = chantierToUpdate.periodeChantiers.findIndex(
      (e) => e.id == idPeriode
    );
    let periodeToUpdate = chantierToUpdate.periodeChantiers[indexPeriode];
    let periodeUpdated = { ...periodeToUpdate, [accessor]: value };

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers[indexPeriode] = periodeUpdated;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleAddPeriode = (idChantier, periode) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];

    let arrayPeriode = [...chantierToUpdate.periodeChantiers, periode];

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers = arrayPeriode;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleResetChantier = (idchantier, reset) => {
    let index = chantiers.findIndex((e) => e.id == idchantier);
    let chantierReseted = { ...reset };
    const newArray = [...chantiers];
    newArray[index] = chantierReseted;

    setChantiers(newArray);
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  let chantierHtml = chantiers?.map((e) => (
    <BlocChantierSynthese
      key={e.id}
      history={props.history}
      chantier={e}
      affaire={props.affaire}
      handleGetAll={handleGetAll}
      update={updateOne}
      handleChange={handleChangeUpdate}
      handleChangePeriode={handleChangePeriodeUpdate}
      handleAddPeriode={handleAddPeriode}
      handleResetChantier={handleResetChantier}
    />
  ));

  return <>{chantierHtml}</>;
};

export { ChantierContainer };
