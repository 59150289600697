import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  ButtonIcon,
  ButtonIconWithSmall,
  DataFieldColumn,
} from "_components";
import { Input } from "_components/Input";
import {
  ChantierService,
  ContactService,
  MaterielService,
  PeriodeService,
  PersonnelService,
} from "_services";
import { VehiculeService } from "_services/VehiculeService";
import moment from "moment";

import React, { useEffect, useState } from "react";

const getAllMaterielLocationProxy = (search) => {
  return MaterielService.getAllSolSolution({
    num_serie: search,
  });
};

const getArrayOfWeekByYear = (year) => {
  if (year == undefined) return [];
  let start = moment(new Date(year, 1, 1));
  let now = moment();

  let arrayWeeks = [];
  if (now.year() == start.year()) {
    for (let i = now.isoWeek() + 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  } else {
    for (let i = 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  }
  return arrayWeeks;
};

const getArrayOfYears = () => {
  let now = moment();
  let year = now.year();
  let arrayYears = [];
  for (let i = 0; i < 3; i++) {
    arrayYears.push(year + i);
  }
  return arrayYears;
};

const BlocChantierColonne = (props) => {
  let [editing, setEditing] = useState(false);
  let [page, setPage] = useState(1);
  let [savedChantier, setSavedChantier] = useState(null);
  let [forceUpdateWeek, setForceUpdateWeek] = useState(() => null);

  const serviceGetContactsByClientId = () => {
    if (chantier?.affaire?.client?.id) {
      return ContactService.getByIdClient(chantier?.affaire?.client?.id);
    }
  };

  let [chantier, setChantier] = useState({});

  const handleGetAll = () => {
    ChantierService.getOneById(props.chantierId).then((e) =>
      setChantier(e.data)
    );
  };

  const update = () => {
    ChantierService.put(chantier)
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  const handleChangeUpdate = (accessor, value) => {
    let chantierToUpdate = chantier;
    let chantierUpdated = { ...chantierToUpdate, [accessor]: value };
    if (accessor == "anneeChantier") {
      forceUpdateWeek();
      handleChangeUpdate("semaineChantier", null);
    }
    setChantier(chantierUpdated);
  };

  const handleChangePeriodeUpdate = (accessor, value, idPeriode) => {
    let chantierToUpdate = chantier;
    let indexPeriode = chantierToUpdate.periodeChantiers.findIndex(
      (e) => e.id == idPeriode
    );
    let periodeToUpdate = chantierToUpdate.periodeChantiers[indexPeriode];
    let periodeUpdated = { ...periodeToUpdate, [accessor]: value };

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers[indexPeriode] = periodeUpdated;

    setChantier(chantierUpdated);
  };

  const handleAddPeriode = () => {
    let chantierToUpdate = chantier;

    let arrayPeriode = [...chantierToUpdate.periodeChantiers, {}];

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers = arrayPeriode;

    setChantier(chantierUpdated);
  };

  const handleResetChantier = () => {
    setChantier({ ...savedChantier });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  let boxHeader = (
    <>
      <div className="col-6 align-self-center py-2">
        <div>Chantier</div>
      </div>
      <div className="col-6 text-end py-2">
        <ButtonIcon
          icon={faPlus}
          className="btn btn-success text-light ms-1"
          style={!editing ? { display: "none" } : { width: "42px" }}
          onClick={() => {
            handleAddPeriode();
            setEditing(true);
            setPage(chantier?.periodeChantiers?.length);
          }}
        />
        <ButtonIcon
          icon={faCheck}
          className="btn btn-success text-light ms-1"
          style={!editing ? { display: "none" } : { width: "42px" }}
          onClick={() => {
            update(chantier.id);
            setEditing(false);
          }}
        ></ButtonIcon>
        <ButtonIcon
          icon={faTimes}
          className="btn btn-danger text-light ms-1"
          onClick={() => {
            setEditing(false);
            handleResetChantier();
          }}
          style={!editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
        <ButtonIcon
          icon={faPen}
          className="btn btn-secondary text-light ms-1"
          onClick={() => {
            setEditing(true);
            setSavedChantier({ ...chantier });
          }}
          style={editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
      </div>
    </>
  );

  let box = editing ? (
    <Box
      header={boxHeader}
      body={
        <div className="row">
          <div className="col">
            <div>
              <Input
                label="Année"
                value={chantier.anneeChantier}
                accessor="anneeChantier"
                handleBlur={handleChangeUpdate}
                type="selectSearch"
                options={getArrayOfYears()}
              />
            </div>
            <div>
              <Input
                label="Semaine"
                value={chantier.semaineChantier}
                accessor="semaineChantier"
                handleBlur={handleChangeUpdate}
                type="selectSearch"
                options={getArrayOfWeekByYear(chantier.anneeChantier)}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateWeek(() => callback)
                }
              />
            </div>
          </div>

          <div className="col">
            <div>
              <Input
                label="PP"
                value={chantier.planPrevention}
                accessor="planPrevention"
                handleChange={handleChangeUpdate}
                type="checkbox"
              />
            </div>
            <div>
              <Input
                label="HeureRDV"
                value={chantier.heureRDV}
                accessor="heureRDV"
                handleChange={handleChangeUpdate}
                type="time"
              />
            </div>
            <div>
              <Input
                label="Contact"
                value={chantier.contact}
                accessor="contact"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                service={serviceGetContactsByClientId}
                optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
              />
            </div>
            <div>
              <Input
                label="Labo"
                value={chantier.labo}
                accessor="labo"
                handleChange={handleChangeUpdate}
                type="checkbox"
              />
            </div>
            <div>
              <Input
                label="Lieu RDV"
                value={chantier.lieuRDV}
                accessor="lieuRDV"
                handleChange={handleChangeUpdate}
                type="text"
              />
            </div>
          </div>
          <div className="col">
            <div>
              <Input
                label="Nuits prévues"
                value={chantier.nbNuitsPrev}
                accessor="nbNuitsPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
              />
            </div>
            <div>
              <Input
                label="Jours prévus"
                value={chantier.nbJoursPrev}
                accessor="nbJoursPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
              />
            </div>
            <div>
              <Input
                label="Hôtel"
                value={chantier.hotel}
                accessor="hotel"
                handleBlur={handleChangeUpdate}
                type="text"
              />
            </div>
            <div>
              <Input
                label="Heure Arrivée bureau"
                value={chantier.heureArriveeBureau}
                accessor="heureArriveeBureau"
                handleChange={handleChangeUpdate}
                type="time"
              />
            </div>
            <div>
              <Input
                label="Nombre de sondages prévus"
                value={chantier.nbSondagesPrev}
                accessor="nbSondagesPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
              />
            </div>
          </div>
          <div className="col">
            <Input
              label="Techniciens"
              value={chantier.techniciens}
              accessor="techniciens"
              handleChange={handleChangeUpdate}
              type="multipleSelectSearch"
              service={PersonnelService.getTechniciens}
              optionFieldToDisplay={["nom", "prenom"]}
              valueFieldToDisplay={["nom", "prenom"]}
            />
            <Input
              label="Vehicules"
              value={chantier.vehicules}
              accessor="vehicules"
              handleChange={handleChangeUpdate}
              type="multipleSelectSearch"
              service={VehiculeService.getAll}
              optionFieldToDisplay={"designation"}
              valueFieldToDisplay={"designation"}
            />
          </div>
          <div className="col">
            <div>
              <Input
                label="Preparateur Endo"
                value={chantier.preparateurEndo}
                accessor="preparateurEndo"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
                service={PersonnelService.getAll}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
              />
            </div>
            <div>
              <Input
                label="Materiel Endo"
                value={chantier.materielEndo}
                accessor="materielEndo"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                service={getAllMaterielLocationProxy}
                optionFieldToDisplay={[
                  "type_Materiel.designation",
                  "num_Serie",
                ]}
                valueFieldToDisplay={["type_Materiel.designation", "num_Serie"]}
              />
            </div>
            <div>
              <Input
                label="PreparateurPanda"
                value={chantier.preparateurPanda}
                accessor="preparateurPanda"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
                service={PersonnelService.getAll}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
              />
            </div>
            <div>
              <Input
                label="Materiel Panda"
                value={chantier.materielPanda}
                accessor="materielPanda"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                service={getAllMaterielLocationProxy}
                optionFieldToDisplay={[
                  "type_Materiel.designation",
                  "num_Serie",
                ]}
                valueFieldToDisplay={["type_Materiel.designation", "num_Serie"]}
              />
            </div>
            <div>
              <Input
                label="Materiel Spécifique"
                value={chantier.materielSpecifique}
                accessor="materielSpecifique"
                handleChange={handleChangeUpdate}
                type="text"
                optionFieldToDisplay={[
                  "type_Materiel.designation",
                  "num_Serie",
                ]}
                valueFieldToDisplay={["type_Materiel.designation", "num_Serie"]}
              />
            </div>
          </div>
          <div className="col">
            <Input
              label="Commentaire"
              value={chantier.commentaire}
              accessor="commentaire"
              handleChange={handleChangeUpdate}
              type="textArea"
            />
          </div>
        </div>
      }
    ></Box>
  ) : (
    <Box
      header={boxHeader}
      body={
        <div className="row">
          <div className="col">
            <div>
              <DataFieldColumn
                label="Année"
                type="text"
                value={chantier.anneeChantier}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Semaine"
                type="text"
                value={chantier.semaineChantier}
              />
            </div>

            <div>
              <DataFieldColumn
                label="PP"
                type="text"
                value={chantier.planPrevention}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="HeureRDV"
                type="text"
                value={chantier.heureRDV}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Contact"
                type="text"
                value={chantier.contact}
                functionAppliedToValue={(value) => {
                  return value ? (
                    <a
                      href={"/contacts/" + value.id}
                      target="_blank"
                      data-text={value.nom_Contact + " " + value.prenom_Contact}
                      className="fixWidthHover"
                    >
                      {value.nom_Contact + " " + value.prenom_Contact}
                    </a>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Labo"
                type="text"
                value={chantier.labo}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Lieu RDV"
                type="text"
                value={chantier.lieuRDV}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="nuitprev"
                type="text"
                value={chantier.nbNuitsPrev}
              />
            </div>
            <div>
              <DataFieldColumn
                label="jourprev"
                type="text"
                value={chantier.nbJoursPrev}
              />
            </div>
            <div>
              <DataFieldColumn
                label="hotel"
                type="text"
                value={chantier.hotel}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Heure Bureau"
                type="text"
                value={chantier.heureArriveeBureau}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Sondages prev"
                type="text"
                value={chantier.nbSondagesPrev}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="techniciens"
                type="text"
                value={chantier.techniciens}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.initiales}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Vehicules"
                type="text"
                value={chantier.vehicules}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.designation}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>

            <div>
              <DataFieldColumn
                label="Prepa Endo"
                type="text"
                value={chantier.preparateurEndo}
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Endo"
                type="text"
                value={chantier.materielEndo}
                functionAppliedToValue={(value) => {
                  return value ? value.num_Serie : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Prepa Panda"
                type="text"
                value={chantier.preparateurPanda}
                functionAppliedToValue={(value) => {
                  return value ? value.nom + " " + value.prenom : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Panda"
                type="text"
                value={chantier.materielPanda}
                functionAppliedToValue={(value) => {
                  return value ? value.num_Serie : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Mtl Spé"
                type="text"
                value={chantier.materielSpecifique}
              />
            </div>
          </div>
          <div className="col">
            <DataFieldColumn
              label="Commentaire"
              type="text"
              value={chantier.commentaire}
            />
          </div>
        </div>
      }
    ></Box>
  );

  let box2 = editing ? (
    <Box
      header={boxHeader}
      body={
        <table className="tableHistorique table table-striped table-bordered table-sm">
          <thead></thead>
          <tbody>
            <tr>
              <th className="text-nowrap ps-2">Périodes</th>
              <td>
                <Input
                  value={chantier?.periodeChantiers?.[page - 1]?.periode}
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id + ".periode"
                  }
                  handleChange={handleChangePeriodeUpdate}
                  type="selectSearch"
                  service={PeriodeService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">ITC réelles</th>
              <td>
                <Input
                  value={chantier?.periodeChantiers?.[page - 1]?.itcReelles}
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id + ".itcReelles"
                  }
                  handleBlur={handleChangePeriodeUpdate}
                  type="decimal"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Heures prev</th>
              <td>
                <Input
                  value={chantier?.periodeChantiers?.[page - 1]?.heuresPrev}
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id + ".heuresPrev"
                  }
                  handleBlur={handleChangePeriodeUpdate}
                  type="decimal"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Heures réalisées</th>
              <td>
                <Input
                  value={
                    chantier?.periodeChantiers?.[page - 1]?.heuresRealisees
                  }
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id +
                    ".heuresRealisees"
                  }
                  handleBlur={handleChangePeriodeUpdate}
                  type="decimal"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Quantités réalisées</th>
              <td>
                <Input
                  value={
                    chantier?.periodeChantiers?.[page - 1]?.quantiteRealisee
                  }
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id +
                    ".quantiteRealisee"
                  }
                  handleBlur={handleChangePeriodeUpdate}
                  type="decimal"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Chantier terminé</th>
              <td>
                <Input
                  value={
                    chantier?.periodeChantiers?.[page - 1]?.chantierTermine
                  }
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id +
                    ".chantierTermine"
                  }
                  handleChange={handleChangePeriodeUpdate}
                  type="checkbox"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Pb rencontrés</th>
              <td>
                <Input
                  value={
                    chantier?.periodeChantiers?.[page - 1]?.problemesRencontres
                  }
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id +
                    ".problemeRencontres"
                  }
                  handleChange={handleChangePeriodeUpdate}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Nbre points réalisés</th>
              <td>
                <Input
                  value={
                    chantier?.periodeChantiers?.[page - 1]
                      ?.nombreSondageRealises
                  }
                  accessor={
                    chantier?.periodeChantiers?.[page - 1]?.id +
                    ".nombreSondageRealises"
                  }
                  handleBlur={handleChangePeriodeUpdate}
                  type="decimal"
                />
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Jour/Nuit</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                  ? "Nuit"
                  : "Jour"}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Reliquat</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.periode?.reliquat}
              </td>
            </tr>
          </tbody>
        </table>
      }
    ></Box>
  ) : (
    <Box
      header={boxHeader}
      body={
        <table className="tableHistorique table table-striped table-bordered table-sm">
          <thead></thead>
          <tbody>
            <tr>
              <th className="text-nowrap ps-2">Périodes</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.periode.designation}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">ITC réelles</th>

              <td>{chantier?.periodeChantiers?.[page - 1]?.itcReelles}</td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Heures prev</th>
              <td>{chantier?.periodeChantiers?.[page - 1]?.heuresPrev}</td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Heures réalisées</th>
              <td>{chantier?.periodeChantiers?.[page - 1]?.heuresRealisees}</td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Quantités réalisées</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.quantiteRealisee}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Chantier terminé</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.chantierTermine
                  ? "oui"
                  : "non"}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Pb rencontrés</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.problemesRencontres}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Nbre points réalisés</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.nombreSondageRealises}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Jour/Nuit</th>
              <td>
                {chantier?.periodeChantiers?.[page - 1]?.isNuit
                  ? "Nuit"
                  : "Jour"}
              </td>
            </tr>
            <tr>
              <th className="text-nowrap ps-2">Reliquat</th>
              <td>{chantier?.periodeChantiers?.[page - 1]?.reliquat}</td>
            </tr>
          </tbody>
        </table>
      }
    ></Box>
  );

  let buttonAddPeriode = (
    <ButtonIconWithSmall
      icon={faPlus}
      className="btn btn-success text-light ms-1"
      onClick={() => {
        handleAddPeriode();
        setEditing(true);
      }}
    />
  );

  let blocPage =
    chantier?.periodeChantiers?.length > 0 ? (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          className="FlecheGauche col-"
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
          onClick={() => (page > 1 ? setPage(page - 1) : null)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            height="40px"
            width="40px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 490 490"
          >
            <g>
              <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507   " />
            </g>
          </svg>
        </div>
        <div
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
        >
          Période {page}/{chantier?.periodeChantiers?.length}
        </div>

        <div
          className="Fleche droite"
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
          onClick={() =>
            page < chantier?.periodeChantiers?.length ? setPage(page + 1) : null
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            height="40px"
            width="40px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 490 490"
            transform="rotate(180)"
          >
            <g>
              <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 " />
            </g>
          </svg>
        </div>
      </div>
    ) : (
      <div>
        Aucune période : <div>{buttonAddPeriode}</div>
      </div>
    );

  return (
    <div>
      <div>{box}</div>
      {blocPage}
      {chantier?.periodeChantiers?.length > 0 ? box2 : null}
    </div>
  );
};

export { BlocChantierColonne };
