import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { isEqual } from "lodash";
import { sanitizeValue, formatValueDecimal } from "_utils/DecimalFunctions";

/**
 * Génère le composant Input
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'objet lié à cet input
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Input
 */
class Decimal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: formatValueDecimal(
        this.props.value,
        props.ignoredValues,
        this.props.numberOfDecimals
      ),
    };
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        value: formatValueDecimal(
          this.props.value,
          this.props.ignoredValues,
          this.props.numberOfDecimals
        ),
      });
    }
  }

  handleOnBlur(event) {
    let value = formatValueDecimal(
      event.target.value,
      this.props.ignoredValues,
      this.props.numberOfDecimals
    );
    this.setState({
      value: value,
    });
    if (this.props.handleBlur) {
      this.props.handleBlur(this.props.accessor, sanitizeValue(value));
    }
  }

  render() {
    return (
      <>
        {this.props.label ? (
          <div className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </div>
        ) : (
          ""
        )}
        <div className="input-decimal input-group has-validation">
          <input
            className="form-control"
            style={this.props.style}
            name={this.props.accessor}
            required={this.props.required}
            value={this.state.value ?? ""}
            onChange={(event) => {
              this.setState({ value: event.target.value });
            }}
            type="text"
            onBlur={(event) => {
              this.handleOnBlur(event);
            }}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          {this.props.showClearButton ? (
            <ButtonIcon
              id={"inputGroup" + this.props.accessor}
              smallText=""
              icon={faTimes}
              iconSize="sm"
              onClick={() => {
                this.setState({ value: null });
                this.props.handleChange(this.props.accessor, null);
              }}
              className="btn btn-danger"
              tabIndex="-1"
            ></ButtonIcon>
          ) : null}
          {this.props.showValidator ? (
            <div
              id={"validation" + this.props.accessor}
              className="invalid-feedback"
            >
              {this.props.invalidText}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

Decimal.propTypes = {
  value: PropTypes.any,
  ignoredValues: PropTypes.any,
  numberOfDecimals: PropTypes.number,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  invalidText: PropTypes.string,
  disabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  showClearButton: PropTypes.bool,
};

Decimal.defaultProps = {
  value: 0,
  ignoredValues: [],
  numberOfDecimals: 2,
  required: false,
  style: null,
};

export { Decimal };
