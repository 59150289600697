import http from "http-common";

class ClientService {
  static cache = null;
  static cacheSS = null;
  static loading = true;
  static loadingSS = true;

  /**
   * Fonction permettant de récuperer tous les clients avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des clients respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    if (params) {
      return ClientService.get(params);
    } else {
      if (ClientService.loading) {
        ClientService.cache = ClientService.get();
        ClientService.loading = false;
      }
      return ClientService.cache;
    }
  }

  static get(params) {
    return http.get("/clients/", { params });
  }

  static getAllViaPost(params) {
    return http.post("/clients/get", params);
  }

  static getSyntheseById(id, produitInternes) {
    return http.get("/clients/synthese/" + id, {
      params: {
        produitInternes: produitInternes,
      },
    });
  }

  /**
   * Fonction permettant de récuperer les clients avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des clients respectant les critères de params et le nombre de page pour ces critères
   */
  static getForSelectSearch(params) {
    if (params) {
      return ClientService.getForSS(params);
    } else {
      if (ClientService.loadingSS) {
        ClientService.cacheSS = ClientService.getForSS();
        ClientService.loadingSS = false;
      }
      return ClientService.cacheSS;
    }
  }

  static getForSS(params) {
    return http.get("/clients/", {
      params: {
        orderBy: "nom_Client",
        order: "ASC",
        nom_Client: params,
        size: 20,
        actif: true,
      },
    });
  }

  /**
   * Fonction permettant de créer un nouveau client pour un client donné
   * @param {*} client, le client à ajouter
   * @returns le client crée avec son id
   */
  static post(client) {
    return http.post("/clients", client);
  }

  /**
   * Fonction permettant de mettre à jour un client
   * @param {*} client, le client à mettre à jour
   * @returns
   */
  static put(client) {
    return http.put("/clients/", client);
  }

  /**
   * Fonction permettant d'ajouter un historique à un client.
   * @param {*} historique, l'historique à ajouter.
   * @returns
   */
  static postHistorique(clientid, historique) {
    return http.post("/clients/" + clientid + "/historique", historique);
  }

  /**
   * Fonction permettant de récuperer un client via son id
   * @param {*} id, id de du client à récupérer
   * @returns le client souhaité
   */
  static getById(id) {
    return http.get("/clients/" + id);
  }

  static getAllAdressesById(id) {
    if (id) {
      return http.get("/clients/" + id + "/addresses");
    } else {
      return new Promise(() => {});
    }
  }

  static getAllClientsByCompteTier(compteTier) {
    if (compteTier) {
      return http.get("/clients/comptetier/" + compteTier);
    } else {
      return new Promise(() => {});
    }
  }

  static getAllClientsWithCompteTier() {
    return http.get("/clients/withcomptetier/");
  }

  static getAllClientsWithCompteTierButNoLicence(nom_Client) {
    return http.get("/clients/withcomptetierandnolicence", {
      params: {
        nom_Client: nom_Client,
      },
    });
  }

  /**
   * Fonction permettant de supprimer un client
   * @param {*} client, le client à supprimer
   * @returns
   */
  static delete(client) {
    return http.delete("/clients/" + client.id);
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/clients/extraction?" + params,
      method: "GET",
      responseType: "blob",
    });
  }

  static ExtractViaPost(payload) {
    console.log(payload);
    return http({
      url: "/clients/extraction",
      method: "POST",
      responseType: "blob",
      data: payload,
    });
  }

  static PrintImpaye(facture, idclient, modele) {
    return http({
      url: "/clients/impressionImpaye/" + modele + "/" + idclient,
      method: "PUT",
      responseType: "blob",
      data: { ...facture },
    });
  }

  /**
   * @returns un fichier
   */
  static printFicheClient(idClient) {
    return http({
      url: "/clients/ficheClient/" + idClient,
      method: "GET",
      responseType: "blob",
    });
  }

  static getClientsGeomarket() {
    return http.get("/clients/geomarket");
  }
}

export { ClientService };
