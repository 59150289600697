import React, { useState, useEffect, useMemo, useReducer } from "react";
import * as FileSaver from "file-saver";

import { useTable, useExpanded, useRowSelect } from "react-table";
import PropTypes from "prop-types";

import TablePagination from "@mui/material/TablePagination";
import { Menu, MenuItem } from "@mui/material/";

import {
  useNonInitialEffect,
  ButtonIcon,
  AlertDialogWithButton,
} from "_components";

import "./ReactTableServerSide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faDownload,
  faColumns,
  faGlobeEurope,
  faUser,
  faUsersSlash,
  faPlus,
  faExternalLinkAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  //RedirectToDetails,
  RedirectToDetailsNewTab,
  IfNullToText,
} from "_utils";
import { Input } from "_components/Input";
import { App } from "App";

const CellWithRedirection = (props) => {
  return (
    <div className="d-flex justify-content-between mx-0 my-1">
      {/* <div
        className="hoverPointer ficheClient flex-grow-1"
        onClick={() => {
          RedirectToDetails(props.history, props.redirection, props.id);
        }}
      > */}
      <a
        className="fixWidthHover"
        href={props.redirection + props.id}
        data-text={IfNullToText(props.value)}
      >
        {IfNullToText(props.value)}
      </a>

      <div className="align-self-center" style={{ width: "25px" }}>
        <ButtonIcon
          icon={faExternalLinkAlt}
          className="btn btn-primary p-0 m-0 align-self-center exportToDetails ficheClientTab"
          onClick={() => {
            RedirectToDetailsNewTab(props.redirection, props.id);
          }}
        ></ButtonIcon>
      </div>
    </div>
  );
};

CellWithRedirection.propTypes = {
  history: PropTypes.object,
  redirection: PropTypes.string,
  value: PropTypes.any,
  id: PropTypes.any,
};

/**
 * Génère les checkbox pour cacher/afficher les colonnes du tableau
 */
const IndeterminateCheckbox = React.forwardRef(function IndeterminateCheckbox(
  { indeterminate, ...rest },
  ref
) {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any,
};

/**
 * Génère un composant tableau server-side avec des composants de filtration
 * @param {*} props, ensemble des propriétés passées au tableau
 *      @property {func} service (obligatoire), fonction provenant d'un service permettant de récupérer les données à charger dans le tableau. (cette fonction devra être capable de gérer des paramètres passés lors de la requête)
 *      @property {string} orderByDefault (obligatoire), nom de la colonne sur lequel l'ordre sera fait par défaut
 *      @property {string} orderDefault (optionel), "ASC" or "DESC", spécifie l'ordre du tri. Par défaut : "ASC".
 *      @property {string} localStorageKeyColumns (semi-obligatoire pour un fonctionnement optimal), nom de la clé de stockage pour sauvegarder les colonnes du tableau lors de futures connexion
 *      @property {array} columns (obligatoire), les colonnes à affichées dans le tableau
 *          @property {string} Header (obligatoire), titre de la colonne
 *          @property {string} id (optionnel), id de la colonne, obligatoire si plusieurs colonnes ont le meme accesseur
 *          @property {string} accessor (obligatoire), accesseur des données récupérées depuis le service (généralement nom de la colonne en base de données)
 *          @property {string} search {optionnel}, permet de changer la valeur de l'accesseur lors du passage des paramètres de la requête faite au service (getSearchParams)
 *          @property {boolean} hideable (optionnel), false si non précisé, si true alors un bouton permettant de cacher / afficher la colonne sera affiché
 *          @property {boolean} sortable (optionnel), false si non précisé, si true alors un bouton permettant de trier la colonne sera affiché
 *          @property {boolean} exportable (optionnel), false si non précisé, si true alors le champs sera considéré lors de l'export
 *          @property {string} minWidth (optionnel), longueur minimale de la colonne
 *          @property {string} type (optionnel), une valeur parmi [null, "text", "selectSearch", ...], permet de générer un champ input en fonction de la valeur passée :
 *                null           : Pas de champ généré
 *                text           : champ text généré
 *                select         : champ select généré
 *          @property {*} inputOptions (optionnel), options de l'élément input choisi, les options dépendent du type choisi :
 *                null           : Pas d'options
 *                input          : {string} placeholder, le placeholder qui sera affiché
 *                inputDatalist  : {array} options : ["placeholder", ["value_1", "value_2", "value_3", ...], tableau contenant le placeholder de l'input ainsi qu'un tableau contenant les valeurs de la datalist
 *                select         : {array} options : ["value_1", "value_2", ...], tableau contenant les valeurs du select
 *          @property {string} inputOptionsValues (optionnel), valeurs des options du select associé, permet d'afficher des chaines de caractères différentes de celles utilisées pour la propriété "value" du select
 *          @property {function} Cell (optionnel), permet de redéfinir le rendu d'une cellule
 *      @property {object} objectFieldToExport (optionnel), object permettant d'exporter une valeur spécifique d'un objet. ex : {adresses: "rue"} permet d'exporter pour l'object adresses la propriété "rue" uniquement
 *      @property {array} pageSizes (optionnel), tableau contenant la taille des pages que l'on peut séléctionnée pour l'affichage
 *      @property {array} dataIdsToLoad (optionnel), tableau optionnel permettant d'afficher uniquement une liste d'éléments spécifiques en passant leurs identifiants
 *      @property {function} subTableComponent (optionnel), function ou composant react renvoyant un composant à rendre dans une sous ligne du tableau
 * @returns {ReactTableServerSide} , un tableau avec ses éléments de filtration
 */
const ReactTableServerSide = function ReactTableServerSide(props) {
  /*******  Hooks *******/

  /* Hook des données à afficher */
  const [datas, setDatas] = useState([]);

  /* Hook de la pagination (numéro de page, nombres d'objets total de la recherche, taille de la page) */
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(props.pageSizes[1]);

  /* Initialisation des champs de recherche pour le hook searchInputs*/
  let searchElementsInputs = {};
  let searchElementsInputsFieldValues = {};
  for (let key in props.columns) {
    // Si la propriété field est définie, on l'utilise pour définir les clés des dictionnaires searchElementsInputs et searchElementsInputsFieldValues.
    // Sinon, on utilise l'accessor en tant que clé.
    if (props.columns[key].search) {
      // Valeur par défaut si non définie : ""
      if (props.columns[key].defaultValue === undefined) {
        searchElementsInputs[props.columns[key].search] = "";
        searchElementsInputsFieldValues[props.columns[key].search] = "";
      } else if (props.columns[key].defaultValue === null) {
        searchElementsInputs[props.columns[key].search] = null;
        searchElementsInputsFieldValues[props.columns[key].search] = null;
      } else {
        // Si une valeur par défaut est définie, on l'utilise pour le dictionnaire searchElementsInputsFieldValues.
        // Puisqu'on peut fournir un objet (pour les champs selectSearch), on doit récupérer le champ à retourné pour le dictionnaire searchElementsInputs
        //   (le nom du champ dudit objet est défini par la propriété optionFieldToReturn).
        searchElementsInputsFieldValues[props.columns[key].search] =
          props.columns[key].defaultValue;
        if (
          typeof props.columns[key].defaultValue === "object" &&
          props.columns[key].optionFieldToReturn !== undefined
        ) {
          searchElementsInputs[props.columns[key].search] =
            props.columns[key].defaultValue[
              props.columns[key].optionFieldToReturn
            ];
        } else {
          searchElementsInputs[props.columns[key].search] =
            props.columns[key].defaultValue;
        }
      }
    } else {
      if (props.columns[key].defaultValue === undefined) {
        searchElementsInputs[props.columns[key].accessor] = "";
        searchElementsInputsFieldValues[props.columns[key].accessor] = "";
      } else if (props.columns[key].defaultValue === null) {
        searchElementsInputs[props.columns[key].accessor] = null;
        searchElementsInputsFieldValues[props.columns[key].accessor] = null;
      } else {
        searchElementsInputsFieldValues[props.columns[key].accessor] =
          props.columns[key].defaultValue;
        if (
          typeof props.columns[key].defaultValue === "object" &&
          props.columns[key].optionFieldToReturn !== undefined
        ) {
          searchElementsInputs[props.columns[key].accessor] =
            props.columns[key].defaultValue[
              props.columns[key].optionFieldToReturn
            ];
        } else {
          searchElementsInputs[props.columns[key].accessor] =
            props.columns[key].defaultValue;
        }
      }
    }
  }

  let searchElementsEmpty = {};
  for (let key in props.columns) {
    let value = "";
    if (props.columns[key].type == "selectSearch") value = null;
    if (props.columns[key].type == "multipleSelectSearch") value = null;
    if (props.columns[key].type == "decimal") value = null;

    if (props.columns[key].search) {
      searchElementsEmpty[props.columns[key].search] = value;
    } else {
      searchElementsEmpty[props.columns[key].accessor] = value;
    }
  }

  /* Hook des champs de recherche (valeurs initiales retournées par les searchInputs / paramètres de requête du back) */
  const [searchInputs, setSearchInputs] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      global: "",
      order: props.orderDefault,
      orderby: props.orderByDefault,
      ...searchElementsInputs,
    }
  );

  /* Hook des champs de recherche (objets/valeurs initiales affichés par les searchInputs) */
  const [searchInputsFieldValues, setSearchInputsFieldValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      global: "",
      order: props.orderDefault,
      orderby: props.orderByDefault,
      ...searchElementsInputsFieldValues,
    }
  );

  const searchInputsEmpty = {
    global: "",
    ...searchElementsEmpty,
  };

  /* Hook des chargement du tableau (export xlsx et chargement des données) */
  const [loading, setLoading] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      xlsx: false,
      datas: false,
    }
  );

  /* Hook de la séléction multiple via les boîtes, permet de sauvegarder la dernière ligne cochée */
  const [previousRowIndex, setPreviousRowIndex] = useState(null);

  /* Hook des identifiants des objets à afficher */
  const [dataIdsToLoad, setdataIdsToLoad] = useState(props.dataIdsToLoad);

  /* Hooks pour les composant Material UI (colonnes a afficher et exports) */
  const [anchorColonnes, setAnchorColonnes] = useState(null);
  const [anchorExport, setAnchorExport] = useState(null);

  const handleClickColonnes = (event) => {
    setAnchorColonnes(event.currentTarget);
  };

  const handleCloseColonnes = () => {
    setAnchorColonnes(null);
  };

  const handleClickExport = (event) => {
    setAnchorExport(event.currentTarget);
  };

  const handleCloseExport = () => {
    setAnchorExport(null);
  };

  /**
   * Permet de gérer le changement de page
   * @param {*} _event, evenement appelant cette fonction
   * @param {int} newPage, numéro de la page à afficher
   */
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  /**
   * Permet de gérer le changement de taille des pages
   * @param {*} event, l'evenement appelant la fonction
   */
  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  /**
   * Permet de mettre a jour la valeur des champs de recherche
   * @param {*} accessor
   * @param {*} value
   */
  const handleChange = (accessor, value) => {
    setSearchInputs({ [accessor]: value });
    setSearchInputsFieldValues({ [accessor]: value });
    setPage(0);
    console.log(accessor, value);

    // Fix : Clear un champ selectSearch devrait selectionner la valeur vide.
    if (value == null) {
      //console.log(searchInputsFieldValues[accessor]);
      if (searchInputsFieldValues[accessor] != null)
        setSearchInputsFieldValues({ [accessor]: null });
      else setSearchInputsFieldValues({ [accessor]: "" });
    }
  };

  // Fix : Suppression de stutter sur les champs text.
  useEffect(() => {
    setSearchInputsFieldValues({});
  }, []);

  /**
   * Récupération des paramètres de la recherche
   * @param {jsonObject} searchInputs, les différents champs de recherche provenant des hooks
   * @param {int} page, la page que l'on souhaite récupérer
   * @param {int} pageSize , le nombre d'éléments par page
   * @returns la liste des paramètres de la recherche
   */
  const getSearchParams = (searchInputs, page, pageSize) => {
    let params = {};

    if (searchInputs) {
      Object.keys(searchInputs).reduce((_acc, key) => {
        if (key != "undefined") {
          params[key] = searchInputs[key];
        }
      }, {});
    }

    if (page) {
      params["page"] = page;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (dataIdsToLoad) {
      params["dataIdsToLoad"] = dataIdsToLoad;
    }
    return params;
  };

  /**
   * Met à jour les hooks de tri par colonne
   * @param {string} columnaccessor, l'accesseur correspondant à la colonne à trier
   */
  const handleColumnOrderChange = (columnaccessor) => {
    let order = "ASC";
    if (columnaccessor == searchInputs.orderby && searchInputs.order == "ASC") {
      order = "DESC";
    }
    setSearchInputs({ ["orderby"]: columnaccessor, ["order"]: order });
  };

  /**
   * Récupère les données depuis un service passé via les props
   */
  const retrieveDatas = () => {
    if (!loading.datas) {
      setLoading({ ["datas"]: true });
      const params = getSearchParams(searchInputs, page, pageSize);
      props
        .service(params)
        .then((response) => {
          const { datas, totalItems } = response.data;
          setDatas(datas);
          setTotalItems(totalItems);
        })
        .catch((e) => {
          console.log(e);
          setDatas([]);
        })
        .finally(() => {
          setLoading({ ["datas"]: false });
        });
    }
  };

  /**
   * Lors du premier chargement de la page permet de créer un stockage local pour les colonnes du tableau à afficher
   */
  const setUpColumns = () => {
    if (localStorage.getItem(props.localStorageKeyColumns) == null) {
      let columns = allColumns.map((col) => {
        return col.isVisible;
      });
      localStorage.setItem(
        props.localStorageKeyColumns,
        JSON.stringify(columns)
      );
    } else {
      let storedColumns = JSON.parse(
        localStorage.getItem(props.localStorageKeyColumns)
      );
      storedColumns.map((isVisible, index) => {
        if (!isVisible) {
          allColumns[index].toggleHidden();
        }
      });
    }
  };

  /**
   * Met à jour le stockage local pour la colonnes séléctionnée lorsque l'on clique sur le bouton pour cacher / afficher cette colonne
   * @param {int} index, l'index de la colonne séléctionnée
   */
  const onHideColumn = (index) => {
    let baseClientStoredColumns = JSON.parse(
      localStorage.getItem(props.localStorageKeyColumns)
    );
    baseClientStoredColumns[index] = !baseClientStoredColumns[index];
    localStorage.setItem(
      props.localStorageKeyColumns,
      JSON.stringify(baseClientStoredColumns)
    );
  };

  /**
   * Met à jour le stockage local pour les colonnes séléctionnées lorsque l'on clique sur le bouton pour cacher / afficher toutes les colonnes
   */
  const onHideAllColumns = () => {
    let baseClientStoredColumns = JSON.parse(
      localStorage.getItem(props.localStorageKeyColumns)
    );
    baseClientStoredColumns = baseClientStoredColumns.map((_val, index) => {
      if (index < allColumns.length && allColumns[index].hideable) {
        return !getToggleHideAllColumnsProps().checked;
      } else {
        return true;
      }
    });
    localStorage.setItem(
      props.localStorageKeyColumns,
      JSON.stringify(baseClientStoredColumns)
    );
  };

  /**
   * Récupération des données à afficher initialement
   */
  useEffect(() => {
    setUpColumns();
    setPage(0);
    retrieveDatas();
  }, []);

  /**
   * Met à jour les données en cas de changement de page
   */
  useNonInitialEffect(() => {
    retrieveDatas();
  }, [page, pageSize]);

  /**
   * Met à jour les éléments en cas de recherche
   */
  useNonInitialEffect(() => {
    setPage(0);
    retrieveDatas();
  }, [searchInputs, dataIdsToLoad]);

  /**
   * Génère les éléments de recherche (input ou select) en fonction de la colonne
   * @param {jsonObject} column, la colonne dont on souhaite générer l'input
   * @returns un élément de recherche
   */
  const generateInputsSearch = (column) => {
    if (column.accessor && column.Header.length) {
      if (column.search) column.accessor = column.search;
      return (
        <Input
          value={searchInputsFieldValues[column.accessor]}
          accessor={column.accessor}
          type={column.type}
          placeholder={column.placeholder}
          service={column.service}
          debounce={column.debounce}
          options={column.options}
          valueFieldToDisplay={column.valueFieldToDisplay}
          optionFieldToDisplay={column.optionFieldToDisplay}
          optionFieldToReturn={column.optionFieldToReturn}
          customFilter={column.customFilter}
          handleChange={handleChange}
          handleBlur={handleChange}
        />
      );
    }
    return <span></span>;
  };

  /**
   * Récupération d'une icône en fonction du hook
   * @param {string} accessor, le nom de l'accesseur
   * @returns une icône
   */
  const getSortIcon = (accessor) => {
    let res = faSort;
    if (accessor == searchInputs.orderby) {
      if (searchInputs.order == "ASC") {
        res = faSortUp;
      } else {
        res = faSortDown;
      }
    }
    return res;
  };

  /**
   * Remise à zéro des champs de recherche
   */
  const clearSearchInputs = () => {
    for (let key in props.columns) {
      if (props.columns[key].search)
        setSearchInputsFieldValues({
          [props.columns[key].search]:
            searchInputsFieldValues[props.columns[key].search] == null
              ? props.columns[key].type == "multipleSelectSearch"
                ? []
                : ""
              : null,
        });
      else
        setSearchInputsFieldValues({
          [props.columns[key].accessor]:
            searchInputsFieldValues[props.columns[key].accessor] == null
              ? props.columns[key].type == "multipleSelectSearch"
                ? []
                : ""
              : null,
        });
    }
    setSearchInputsFieldValues({
      ["global"]: searchInputsFieldValues["global"] == null ? "" : null,
    });
    setSearchInputs({ ...searchInputsEmpty });
  };

  /**
   * Remise à zéro des id à rechercher
   */
  const clearDataIdsToLoad = () => {
    setdataIdsToLoad(null);
  };

  /* Colonnes du tableau */
  const columns = useMemo(() => props.columns, []);

  /**
   * Exporte les données au format .CSV en fonction des paramètres actuels de la recherche
   */
  const exportToXlsx = () => {
    if (!loading["xlsx"] && props.serviceExtract) {
      setLoading({ ["xlsx"]: true });
      const params = getSearchParams(searchInputs);
      props
        .serviceExtract(params)
        .then((response) => {
          var blob = new Blob([response?.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "extraction" + ".xlsx");
          setLoading({ ["xlsx"]: false });
        })
        .catch((e) => {
          console.log(e);
          setLoading({ ["xlsx"]: false });
        });
    }
  };

  /* Paramètres du tableau */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
    getToggleHideAllColumnsProps,
    // Permet de récupérer les valeurs des lignes selectionnées
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: datas,
    },
    useExpanded,
    useRowSelect
  );

  /**
   * Génère un composant tableau contenant la liste des contacts
   * @param {array} contacts, tableau des contacts
   * @returns un composant tableau
   */
  const subTable = (subObjects) => {
    return (
      <props.subTableComponent
        subObjects={subObjects}
      ></props.subTableComponent>
    );
  };

  /**
   * Permet la séléction multiple de checboxs en maintenant la touche shift
   * @param {event} e, l'événement initiateur de la fonction
   * @param {row} row, la ligne courante du tableau
   */
  const selectCheckBoxes = (e, row) => {
    if (e.shiftKey && previousRowIndex >= 0) {
      // Si la recherche est plus basse dans le tableau
      if (previousRowIndex < row.index) {
        for (let i = previousRowIndex + 1; i <= row.index; i++) {
          rows[i].toggleRowSelected();
        }
        // Si la recherche est plus haute dans le tableau
      } else {
        for (let i = row.index; i <= previousRowIndex - 1; i++) {
          rows[i].toggleRowSelected();
        }
      }
    } else {
      row.toggleRowSelected();
    }
    setPreviousRowIndex(row.index);
    document.getSelection().removeAllRanges();
  };

  /**
   * Permet de réaliser une action en fonction de la cellule clicquée dans le tableau
   * @param {event} e, l'événement initiateur de la fonction
   * @param {row} row, la ligne courante du tableau
   * @param {cell} cell, la cellule courante du tableau
   */
  const handleTableClick = (e, row, cell) => {
    if (cell.column.id == "selection") {
      selectCheckBoxes(e, row);
    }
  };

  /**
   * Génère le contenu du tableau
   * @returns un composant du contenu du tableau
   */
  const TableComponent = () => {
    return (
      <table
        id="tableau"
        className="table table-striped table-bordered table-sm m-0"
        {...getTableProps()}
      >
        <thead className="">
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                style: { whiteSpace: "nowrap" },
              })}
              key={headerGroupIndex}
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  scope="col"
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth },
                  })}
                  className="hover"
                  onClick={() => {
                    if (column.sortable) {
                      handleColumnOrderChange(
                        column.search ? column.search : column.id
                      );
                    }
                  }}
                  key={columnIndex}
                >
                  {column.render("Header")}
                  {column.sortable ? (
                    <FontAwesomeIcon
                      icon={getSortIcon(
                        column.search ? column.search : column.id
                      )}
                      size="lg"
                      className="orderby sortColumns"
                    />
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <tr scope="row" role="row" id="searchInputs">
            {columns.map((column, columnIndex) => {
              return allColumns[columnIndex].getToggleHiddenProps().checked ? (
                <td
                  className="align-middle searchInput"
                  key={columnIndex}
                  role="cell"
                >
                  {generateInputsSearch(column)}
                </td>
              ) : null;
            })}
          </tr>
          {!loading["datas"] ? (
            rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <React.Fragment key={rowIndex}>
                  <tr
                    scope="row"
                    {...row.getRowProps()}
                    className="hovereffect"
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          className="p-0 align-middle px-1"
                          {...cell.getCellProps()}
                          key={cellIndex}
                          onClick={(e) => handleTableClick(e, row, cell)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {props.subTableComponent && row.isExpanded ? (
                    <tr className="bg-light">
                      <td className="hide-border-right"></td>
                      <td
                        colSpan={visibleColumns.length - 1}
                        className="p-0 align-middle"
                      >
                        {subTable(row.original.contacts)}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <th className="text-center" colSpan={visibleColumns.length}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  className="fa-spin"
                />
              </th>
            </tr>
          )}
          {!datas.length && !loading["datas"] && (
            <tr>
              <th className="text-center " colSpan={visibleColumns.length}>
                <h4 className="mb-0">Pas de résultat</h4>
              </th>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const limiteAlertExportation = 1000;

  /* On retourne tous les composants formant le tableau */
  return (
    <>
      <div
        className="shadow p-3 m-4 bg-body rounded bg-light pageClient"
        id="tableau"
      >
        <div className="d-flex justify-content-between pt-1">
          <div>
            <h2 className="solwayFont nowrap pr-100">{props.title}</h2>
          </div>
          <div className="d-flex align-self-center nowrap">
            <div className="d-flex">
              {dataIdsToLoad ? (
                <ButtonIcon
                  icon={faUsersSlash}
                  iconSize="lg"
                  onClick={clearDataIdsToLoad}
                  className="form-control btn btn-danger ms-1"
                ></ButtonIcon>
              ) : null}

              <div>
                <div id="rechercheGlobale" className="ms-1">
                  <Input
                    value={searchInputsFieldValues["global"]}
                    accessor="global" // Accesseur
                    handleChange={handleChange} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                    inputType="text" // Type de l'input a utiliser
                  />
                </div>
              </div>
              <div className="ms-1">
                <ButtonIcon
                  smallText="Reset"
                  icon={faTimesCircle}
                  iconSize="lg"
                  onClick={clearSearchInputs}
                  id="clearRecherche"
                  className="form-control btn btn-danger mx-1 "
                  tooltip="Vider tous les champs de recherche"
                ></ButtonIcon>
              </div>

              {props.canSelectedDataBeSendToOtherPages ? (
                <div className="ms-1">
                  <ButtonIcon
                    smallText="Géomarketing"
                    icon={faGlobeEurope}
                    iconSize="lg"
                    tooltip="Exporter les clients vers le géomarketing"
                    textOption={
                      selectedFlatRows.length ? (
                        <>
                          ({selectedFlatRows.length + " "}
                          <FontAwesomeIcon icon={faUser} />)
                        </>
                      ) : (
                        "(Tous)"
                      )
                    }
                    id="geomarketing"
                    className="form-control btn btn-primary mx-1"
                  ></ButtonIcon>
                </div>
              ) : null}

              <div className="ms-1">
                <ButtonIcon
                  smallText="Afficher"
                  icon={faColumns}
                  iconSize="lg"
                  onClick={handleClickColonnes}
                  id="filtres"
                  className="form-control btn btn-primary mx-1"
                  tooltip="Filtrer les colonnes du tableau"
                ></ButtonIcon>
              </div>
              <Menu
                className="mt-5"
                anchorEl={anchorColonnes}
                keepMounted
                open={Boolean(anchorColonnes)}
                onClose={handleCloseColonnes}
              >
                <MenuItem>
                  <label className="form-check-label">
                    <IndeterminateCheckbox
                      className="form-check-input mx-1"
                      {...getToggleHideAllColumnsProps()}
                      onClick={onHideAllColumns}
                    />{" "}
                    Afficher tout
                  </label>
                </MenuItem>
                {allColumns.map((column, index) =>
                  column.hideable ? (
                    <MenuItem key={column.id}>
                      <label className="form-check-label w-100">
                        <input
                          className="form-check-input mx-1 "
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                          onClick={(e) => {
                            setSearchInputs({ [column.id]: "" });
                            column.getToggleHiddenProps().onChange(e);
                            onHideColumn(index);
                          }}
                        />
                        {column.Header}
                      </label>
                    </MenuItem>
                  ) : null
                )}
              </Menu>
              {props.displayExtractButton ? (
                <div className="ms-1">
                  <ButtonIcon
                    smallText="Exporter"
                    icon={faDownload}
                    iconSize="lg"
                    onClick={handleClickExport}
                    id="export"
                    className="form-control btn btn-primary mx-1"
                    tooltip="Exporter les données du tableau"
                  ></ButtonIcon>
                </div>
              ) : null}
              <Menu
                className="mt-5"
                anchorEl={anchorExport}
                keepMounted
                open={Boolean(anchorExport)}
                onClose={handleCloseExport}
                disableEnforceFocus
              >
                <MenuItem>
                  {loading["xlsx"] ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="lg"
                      className="fa-spin flex-auto"
                    />
                  ) : props.isLimitedExtraction ? (
                    <button className="btn btn-primary" onClick={exportToXlsx}>
                      Excel (200 lignes max)
                    </button>
                  ) : totalItems > limiteAlertExportation ? (
                    <AlertDialogWithButton
                      button="Excel"
                      title="Attention"
                      body="Il semble que vous essayez d'exporter de nombreuses lignes. Dans le cas où vous souhaitez vraiment exporter, vous pouvez cliquer sur le bouton ci-dessous. Sachez toutefois que la page risque de rester figée pendant l'exportation."
                      valider="Exporter quand même"
                      onClick={exportToXlsx}
                    />
                  ) : (
                    <button className="btn btn-primary" onClick={exportToXlsx}>
                      Excel
                    </button>
                  )}
                </MenuItem>
              </Menu>
              {props.redirectToCreate &&
              App.RightsGuard.current?.hasRight(props.module, "Creation") ==
                "RW" ? (
                <div className="ms-1">
                  <ButtonIcon
                    icon={faPlus}
                    iconSize="lg"
                    className="form-control btn btn-success mx-1"
                    onClick={props.redirectToCreate}
                    id="newClient"
                    tooltip="Créer un nouvel élément"
                  ></ButtonIcon>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-3 table-responsive fill-parent scrollbar">
          {TableComponent()}
        </div>
        <div>
          <TablePagination
            SelectProps={{ id: "paginationSelect" }}
            nextIconButtonProps={{
              id: "paginationNext",
              disabled: loading.datas || totalItems / pageSize < page + 1,
            }}
            backIconButtonProps={{
              disabled: loading.datas || page < 1,
            }}
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={props.pageSizes}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

/* Validation des props */
ReactTableServerSide.propTypes = {
  columns: PropTypes.array,
  dataIdsToLoad: PropTypes.array,
  localStorageKeyColumns: PropTypes.string,
  objectFieldToExport: PropTypes.object,
  orderByDefault: PropTypes.string,
  orderDefault: PropTypes.string,
  pageSizes: PropTypes.array,
  redirectToCreate: PropTypes.func,
  service: PropTypes.func,
  subTableComponent: PropTypes.func,
  title: PropTypes.any,
  canSelectedDataBeSendToOtherPages: PropTypes.bool,
};

/* Valeurs des props par défaut */
ReactTableServerSide.defaultProps = {
  title: "Base",
  pageSizes: [10, 25, 50, 100],
  service: null,
  columns: [],
  localStorageKeyColumns: "TableLocalStorageKey",
  orderDefault: "ASC",
};

export { ReactTableServerSide, CellWithRedirection, IndeterminateCheckbox };
